<template>
    <div id="pageTable">      
        <v-container xl fluid>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" class="mx-0 px-0">
                    <v-breadcrumbs :items="itemsLinks">
                        <template v-slot:divider>
                            <v-icon>chevron_right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" class="mb-5">
                    <v-card elevation="0" class="borde-card cardInformacion">
                            <v-card-title class="justify-center pb-1">
                                <div>
                                    <h2 class="titleModal">Información del préstamo</h2>
                                </div>
                            </v-card-title>
                            <v-card-text class="mt-5">
                                <v-row>
                                    <v-col cols="12" xs="12" sm="6" md="6" class="py-0 ">
                                        <v-text-field 
                                            outlined
                                            label="Empleado" 
                                            class="pa-0 ma-0"
                                            v-model="datosPrestamo.nombre_completo"
                                            readonly
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="2" md="2" class="py-0 ">
                                        <v-text-field 
                                            outlined
                                            label="Fecha de creación" 
                                            class="pa-0 ma-0"
                                            v-model="created_at"
                                            readonly
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="2" md="2" class="py-0 ">
                                        <VuetifyMoneyValidate
                                            outlined
                                            label="Monto"
                                            class="pa-0 ma-0"
                                            prefix="$"
                                            v-model="datosPrestamo.monto"
                                            v-bind:options="options"
                                            readonly
                                        />
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="2" md="2" class="py-0 ">
                                        <VuetifyMoneyValidate
                                            outlined
                                            label="Saldo"
                                            class="pa-0 ma-0"
                                            prefix="$"
                                            v-model="saldo"
                                            v-bind:options="options"
                                            readonly
                                        />
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                        <v-text-field 
                                            outlined
                                            label="Descripción" 
                                            class="pa-0 ma-0"
                                            v-model="datosPrestamo.descripcion"
                                            readonly
                                        >
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                    </v-card>
                </v-col>
                
                <v-col cols="12" lg="12">
                    <data-table
                         ref="tabla"
                        :columns="columns"
                        :tableName="'Abonos'"
                        :items="tableData.data"
                        :paginationInfo="tableData.paginatorInfo"
                        @pagination="getPaginationInfo"
                        :showAdvancedFilters="true"
                        :filters="filters"
                        @setFilters="setFilters"
                        @loadModalData="loadModalData"
                        :perPage="[10,25,50,100]"
                        :loading="isLoading"
                    >
                        <template slot="actionButtonsLeft" v-if="rol != 'empleado'">
                            <div class="botonAgregar">
                                <v-btn
                                rounded
                                color="#004BAF"
                                class="btnAdd"
                                right
                                @click="abrirModal('add')"
                                >
                                <v-icon dark>add</v-icon>Agregar
                                </v-btn>
                            </div>
                        </template>
                        <template slot="filters">
                    <v-col cols="12" xs="12" sm="6" md="6"  class="py-0">
                        <VuetifyDatePicker
                            outlined
                            v-model="fecha_inicio"
                            label="Fecha inicial"
                            placeholder="dd/mm/aaaa"
                            ref="FechaInicial"
                        />
                    </v-col>
                    <v-col cols="12" xs="12" sm="6" md="6"  class="py-0">
                        <VuetifyDatePicker
                            outlined
                            v-model="fecha_final"
                            label="Fecha final"
                            placeholder="dd/mm/aaaa"
                            ref="FechaFinal"
                        />
                    </v-col>
                    <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                        <v-autocomplete
                            outlined
                            label="Tipo de estatus"
                            :items="TiposEstatus"
                            item-text="nombre"
                            item-value="valor"
                            persistent-hint
                            v-model="aplicado"
                            class="pa-0 ma-0"
                        >
                        </v-autocomplete>
                    </v-col>
                </template>
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <td><div>{{dateFormat(item.fecha)}}</div></td>
                                <td><div>{{formatoMoneda(item.monto)}}</div></td>
                                <td v-if="item.tipo == 'EFECTIVO'"><div>Efectivo</div></td>
                                <td v-if="item.tipo == 'NOMINA'"><div>Nómina</div></td>
                                <td v-if="item.aplicado == true">Aplicado</td>
                                <td v-if="item.aplicado == false">No aplicado</td>
                                <td>
                                    <div class="tblOpciones">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" class="botonHover" icon fab dark small @click="abrirModal('update', item)">
                                                <v-icon class="tamIconoBoton invertirColorBotones">edit</v-icon>
                                            </v-btn>
                                            </template>
                                            <span>Editar</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" class="botonHover" icon fab dark small @click="eliminar(item)">
                                                <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                            </v-btn>
                                            </template>
                                            <span>Eliminar</span>
                                        </v-tooltip>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>

            <template class="borde-card">
                <v-dialog v-model="dialog" persistent max-width="600px" class="borde-card" scrollable>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>
                        <v-card elevation="0" class="borde-card">
                            <ValidationObserver ref="form" v-slot="{ invalid }">
                                <v-card-title class="justify-center">
                                    <div class="headerModal">
                                        <img :src="imagen" class="imgModal" />
                                        <h2 class="titleModal">{{ tituloModal }}</h2>
                                    </div>
                                </v-card-title>

                                <v-card-text>
                                    <div id="padre" v-show="isLoadingModal">
                                        <div id="loading">
                                            <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                        </div>
                                    </div>
                                    
                                    <v-container v-show="!isLoadingModal" grid-list-md id="contenedor">
                                        <v-divider class="divider"></v-divider>
                                        <v-row>
                                            <v-col cols="12" xs="12" sm="6" md="6"  class="py-0">
                                                <VuetifyDatePicker
                                                    outlined
                                                    rules="required"
                                                    v-model="abono.fecha"
                                                    label="Fecha"
                                                    placeholder="dd/mm/aaaa"
                                                    ref="FechaAbono"
                                                />
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                                <VuetifyMoneyValidate
                                                    outlined
                                                    rules="required"
                                                    label="Monto"
                                                    class="pa-0 ma-0"
                                                    prefix="$"
                                                    v-model="abono.monto"
                                                    v-bind:options="options"
                                                />
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Tipo pago" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Tipo pago"
                                                        :items="abonoTipos"
                                                        item-text="nombre"
                                                        item-value="valor"
                                                        persistent-hint
                                                        v-model="abono.tipo"
                                                        class="pa-0 ma-0"
                                                        :error-messages="errors" 
                                                        required
                                                    >
                                                    </v-autocomplete>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                                <v-text-field 
                                                    outlined
                                                    label="Comentario" 
                                                    class="pa-0 ma-0" 
                                                    v-model="abono.comentario" 
                                                >
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions>
                                    <v-layout row>
                                        <v-flex class="justify-center">
                                            <div class="btnModal">
                                                <v-btn class="btnGuardar modal-pull-right" @click="guardar()" :disabled="invalid" :loading="isSaving">Guardar</v-btn>
                                                <button class="btnCerrar modal-pull-right" @click="cerrarModal()" :disabled="isSaving">Cerrar</button>
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                </v-card-actions>
                            </ValidationObserver>
                        </v-card>
                </v-dialog>
            </template>

        </v-container>
    </div>
</template>

<script>
import Datatable from '@/components/g-datatable/Datatable.vue';
import queries from '@/queries/Prestamos';
import Notify from '@/plugins/notify';
import apiPrestamos from '@/api/nominas/prestamos';
import VuetifyMoneyValidate from '@/components/VuetifyMoneyValidate.vue';
import VuetifyDatePicker from '@/components/VuetifyDatePicker.vue';


export default {
    components: {
        VuetifyMoneyValidate,
        VuetifyDatePicker,
        'data-table': Datatable,
    },
    data() {
        return {
            columns         : [
                {
                    label: 'Fecha',
                    name: 'fecha',
                    filterable: true
                },
                {
                    label: 'Monto',
                    name: 'monto',
                    filterable: true
                },
                {
                    label: 'Tipo',
                    name: 'tipo',
                    filterable: true
                },
                {
                    label: 'Estatus',
                    name: 'aplicado',
                    filterable: false
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    align: 'center',
                    filterable: false
                }
            ],
            filters         : {AND:[]},
            tableData       : {},
            isLoading       : true,
            paginationData  : {
                numberItems : 10,
                numberPage  : 1
            },
            abono           : {
                id          : null,
                prestamo_id : null,
                fecha       : null,
                monto       : null,
                tipo        : null,
                comentario  : null,
            },
            fecha_inicio    : null,
            fecha_final     : null,
            aplicado        : null,
            menu            : false,
            menuF           : false,
            userData        : this.$session.get('usuario'),
            accion          : null,
            tituloModal     : null,
            dialog          : false,
            isSaving        : false,
            imagen          : '',
            menu            : false,
            datosPrestamo   : null,
            saldo           : null,
            created_at      : null,
            abonoTipos      : [
                {
                    nombre:'Nómina', 
                    valor: 'NOMINA'
                }, 
                {
                    nombre:'Efectivo', 
                    valor: 'EFECTIVO'
                }
            ],
            TiposEstatus    : [
                {
                    nombre:'Todos', 
                    valor: null,
                }, 
                {
                    nombre:'Aplicados', 
                    valor: true
                },
                {
                    nombre:'No aplicados', 
                    valor: false
                }
            ],
            itemsLinks      : [
                {
                    text: 'Préstamos',
                    disabled: false,
                    to: '/prestamos',
                },
                {
                    text: 'Abonos',
                    disabled: true,
                    to: '/abonos',
                },
            ],
            options: {
                locale: "es-MX",
                prefix: "$",
                suffix: "",
                length: 11,
                precision: 2
            },
            isLoadingModal  : false,
            datosLogin      : null,
            rol             : null,
        }
    },
    methods: {
        getPaginationInfo(data) {
            switch(data.type) {
                case "page":
                    this.paginationData.numberPage = data.value
                    break;

                case "itemsToShow":
                    this.paginationData.numberItems = data.value
                    break;
            }
            this.isLoading = true;
        },
        abrirModal(accion, data = null){
            this.accion     = accion;
            this.isLoadingModal  = true;
            this.imagen     = "/static/icon/catalogos.png"


            if(accion == "add"){
                this.tituloModal = "Registrar abono";
                this.imagen         = "/static/modal/prestamoCreate.svg";
                
            } else {
                this.tituloModal = "Actualizar abono";
                this.imagen             = "/static/modal/prestamoUpdate.svg";
                this.abono.id           = data.id;
                this.abono.fecha        = data.fecha;
                this.abono.monto        = data.monto;
                this.abono.tipo         = data.tipo;
                this.abono.comentario   = data.comentario;
                setTimeout(()=>this.$refs.FechaAbono.setDate(data.fecha),200);
            }
            this.isLoadingModal  = false;
            setTimeout(function(){ document.getElementById("btnModal").click(); }, 100);
        },
        cerrarModal() {
            this.dialog     = false;
            this.isSaving   = false;
            this.resetValues();
        },
        resetValues() {
            this.abono.id           = null;
            this.abono.fecha        = null;
            this.abono.monto        = null;
            this.abono.tipo         = null;
            this.abono.comentario   = null;
            this.aplicado           = null;
            if(this.$refs.FechaAbono != undefined || this.$refs.FechaAbono != null){
                this.$refs.FechaAbono.resetDate();
            }
            if(this.$refs.FechaInicial != undefined || this.$refs.FechaInicial != null){
                this.$refs.FechaInicial.resetDate();
            }
            if(this.$refs.FechaFinal != undefined || this.$refs.FechaFinal != null){
                this.$refs.FechaFinal.resetDate();
            }
            this.$nextTick(() => {
                if(this.$refs.form != null){
                    this.$refs.form.reset();
                }
                this.fecha_inicio = null;
                this.fecha_final = null;
                
            });
        },
        async loadModalData(){
            await this.resetValues();
        },
        setFilters() {
            this.filters = {AND:[]};
            
            this.filters.AND.push({column:'PRESTAMO_ID', value:this.datosPrestamo.id});

            if(this.aplicado != null){
                this.filters.AND.push({column:'APLICADO', value:this.aplicado});
            }

            let fecha_inicio = this.fecha_inicio;
            let fecha_final = this.fecha_final;
            let dates = 0;

            if(fecha_inicio != null && fecha_inicio != undefined && fecha_inicio != ""){
                dates = dates+1;
            }

            if(fecha_final != null && fecha_final != undefined && fecha_final != ""){
                dates = dates+1;
            }

            if(dates == 2){
                this.filters.AND.push({column:'FECHA',operator:'BETWEEN', value:[fecha_inicio,fecha_final]});
            }else if(dates == 1){
                Notify.WarningToast("Tienes que completar ambas fechas.");
                this.$apollo.queries.historico_prestamos.skip = true;
                this.resetValues();
                return;
            }
            if(this.$apollo.queries.historico_prestamos.skip){
                this.$apollo.queries.historico_prestamos.skip = false;
            }

            this.isLoading = true;

            this.paginationData.numberPage = 1;
            this.$apollo.queries.historico_prestamos.refetch();
            this.resetValues();
        },
        eliminar(data){
            let comentario = data.comentario != null ? ' '+data.comentario:'';
            Notify.Alert(
                "ADVERTENCIA",
                "¿Estás seguro que deseas eliminar el abono" + comentario + "?",
                "Eliminar",
                () => {
                    this.isLoading = true;
                    apiPrestamos.deleteAbono(data.id).then(response => {
                        Notify.Success("Abono eliminado", "El abono ha sido eliminado satisfactoriamente");
                        this.$apollo.queries.historico_prestamos.refetch();
                        this.$apollo.queries.prestamo.refetch();
                    })
                    .catch(err => {
                        this.isLoading = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            )
        },
        guardar() {
            this.$refs.form.validate().then(success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }

                this.isLoading = true;
                if(this.accion === "add") {
                    apiPrestamos.storeAbono(this.abono)
                    .then((response) => {
                        Notify.Success("Operación exitosa", "El abono se guardó satisfactoriamente.");
                        this.$apollo.queries.historico_prestamos.refetch();
                        this.$apollo.queries.prestamo.refetch();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        this.isLoading = false;
                        this.isSaving = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
                else if(this.accion === "update") {
                    apiPrestamos.updateAbono(this.abono)
                    .then((response) => {
                        Notify.Success("Operación exitosa", "El abono se actualizó satisfactoriamente.");
                        this.$apollo.queries.historico_prestamos.refetch();
                        this.$apollo.queries.prestamo.refetch();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        this.isLoading = false;
                        this.isSaving = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            });
        },
        dateFormat(fecha) {
            let fecha2 = fecha.split("-");
            return fecha2[2] + " / " + fecha2[1]+ " / " + fecha2[0];
        },
        formatoMoneda(value){
            const formatter = new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN',
            })
            return formatter.format(value)
        },
    },
    created(){
        this.datosLogin = this.$session.get("usuario");
        this.rol = this.datosLogin.rol.name;
        if(this.$session.get('datosPrestamo') == null || this.$session.get('datosPrestamo') == undefined){
            this.$router.push('/');
        }
       this.datosPrestamo = this.$session.get('datosPrestamo');
       this.abono.prestamo_id = this.datosPrestamo.id;
       this.filters.AND.push({column:'PRESTAMO_ID', value:this.datosPrestamo.id});
       //this.filters.AND.push({column:'APLICADO', value:true});

    },
    mounted(){
        this.$apollo.queries.historico_prestamos.skip = false;
        this.$apollo.queries.prestamo.skip = false;
    },
    apollo: {
        historico_prestamos: {
            query       : queries.historicoPrestamosTableQuery,
            fetchPolicy : 'network-only',
            variables() {
                this.isLoading =  true;
                return {
                    whereConditions : this.filters,
                    numberItems : this.paginationData.numberItems,
                    numberPage  : this.paginationData.numberPage,
                    fieldOrder  : this.$refs.tabla.sortKey ? this.$refs.tabla.sortKey : 'fecha',
                    Order       : this.$refs.tabla.Order
                }
            },
            skip() {
                return true
            },
            result({loading, error}) {
                this.isLoading  = false;
                this.tableData  = this.historico_prestamos;
            },
        },
        prestamo:{
            query       : queries.getPrestamo,
            fetchPolicy : 'network-only',
            variables() {
                return {
                    id : parseInt(this.datosPrestamo.id)
                }
            },
            skip() {
                return true
            },
            result({loading, error}) {
                this.saldo = this.prestamo.saldo;
                let fecha = this.prestamo.created_at.split("-");
                this.created_at = fecha[2] + "/" + fecha[1]+ "/" + fecha[0];
            }
        }
    }
}
</script>

<style scoped>
    #padre {
        padding-left: 40%;
    }
    #loading {
        display: table-cell;
        vertical-align: middle;
    }
    #contenedor {
        margin-top: -6%;
    }
    .invertirColor {
        color: white;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: rgba(0,0,0,0.54);
    }
    .tamIcono {
        height: 24px !important;
        align-self: center !important;
    }
    .iconoDelgado {
        color: white;
        -webkit-text-stroke-width: 1px;
        -webkit-text-fill-color: rgba(0,0,0,0.54);
    }
    .botonModal.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
        display: none;
    }
    .cardInformacion{
        width: 100%;
    }

</style>
